import React from 'react';
import logoWhite from './blueprintwhite.svg';
import logoBlue from './blueprintblue.svg';
import { Link } from 'react-scroll';
import { Video } from 'cloudinary-react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div id="wrapper">
        {/* header begin */}
        <header className="transparent scroll-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <div className="align-self-center header-col-left">
                    {/* logo begin */}
                    <div id="logo">
                      <a href="#wrapper">
                        <img
                          alt=""
                          className="logo"
                          width={'120px'}
                          src={logoWhite}
                        />
                        <img
                          alt=""
                          className="logo-2"
                          width={'120px'}
                          src={logoBlue}
                        />
                      </a>
                    </div>
                    {/* logo close */}
                  </div>
                  <div className="align-self-center ml-auto header-col-mid">
                    <ul id="mainmenu">
                      <li>
                        <Link to="about" smooth offset={40} duration={500}>
                          {' '}
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="benefits" smooth offset={40} duration={500}>
                          {' '}
                          Benefits
                        </Link>
                      </li>
                      <li>
                        <Link to="partners" smooth offset={-40} duration={500}>
                          {' '}
                          Partners
                        </Link>
                      </li>
                      <li className="signIn mobile-menu">
                        <a
                          className="signIn"
                          href="https://app.blueprintcollaborative.org/sign-in"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Sign In
                        </a>
                      </li>
                      <li className="signUp mobile-menu">
                        <a
                          className="signUp"
                          href="https://blueprint.kinde.com/knock-knock"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Sign Up
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="align-self-center ml-auto header-col-right">
                    {/* <a
                      className="btn-custom bg-white text-dark mr-3 signIn"
                      href="https://app.blueprintcollaborative.org/sign-in"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sign In
                    </a> */}
                    <a
                      className="btn-custom signUp"
                      href="https://blueprint.kinde.com/knock-knock"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sign Up
                    </a>
                    <span id="menu-btn" />
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* header close */}
        {/* content begin */}
        <div className="no-bottom no-top" id="content">
          <div id="top" />

          <div
            className="full-height text-light relative no-top no-bottom vertical-center"
            data-bgimage="url(images/background/main-bg.png) top"
            data-stellar-background-ratio=".5"
          >
            <div className="overlay-gradient t50">
              <div className="center-y relative">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 col-md-12 m-auto text-center">
                      <h1 className="wow fadeInUp" data-wow-delay=".5s">
                        A community and investor portal for deep{' '}
                        <span className="id-color-3">collaboration</span> and
                        full spectrum capital{' '}
                        <span className="id-color-3">investments</span>
                      </h1>
                      <div className="spacer-20" />
                      <Link
                        to="about"
                        smooth
                        offset={-10}
                        duration={750}
                        className="btn-custom wow fadeInUp"
                        style={{ cursor: 'pointer', fontSize: '21px' }}
                        data-wow-delay="1s"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="about" />
          <div
            id="section-services"
            style={{ paddingTop: '150px', paddingBottom: '150px' }}
            data-bgcolor="#F6F7FB"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-8 m-auto">
                  <div className="text-center font-weight-bold">
                    <h2>About Us</h2>
                    <p>
                      Blueprint Collaborative is powered by{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://fullspectrumcapitalpartners.us"
                      >
                        Full Spectrum Capital Partners
                      </a>
                      .
                    </p>
                    <p className="mb-0">
                      Communities across the globe are already solving many of
                      the cultural, political, economic and environmental
                      challenges of our time. Blueprint Collaborative provides
                      the technology to expand access to capital; ensuring that
                      worker- and community-led enterprises receive the
                      investment <br />
                      required for community wealth building and transformation
                    </p>
                  </div>
                  <div className="mt10" id="video-container">
                    <Video
                      controls
                      crop="scale"
                      cloudName="blueprint-collaborative"
                      publicId="BlueprintFINALwith_music_compressed_mp4_1_kyumnr"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Marketplace */}
            <div
              className="container"
              style={{ paddingTop: '150px', paddingBottom: '150px' }}
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 text-center font-weight-bold">
                      <h2>Join our community!</h2>
                      <p>
                        By joining our{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://blueprint.foundation"
                        >
                          community
                        </a>
                        ,{' '} you will be able to access a marketplace of investments and clusters of projects that are working to solve the world's most pressing problems.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <img
                        src="/blueprint_project_view.png"
                        alt="uploading preview"
                        height={800}
                        className="rounded-md bg-white p-2 sm:p-8 md:p-7 shadow-2xl ring-1 ring-gray-900/10"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* testimonials */}
          <div id="benefits" />
          <div
            id="section-testimonial"
            style={{ paddingTop: '150px', paddingBottom: '150px' }}
            data-bgimage="url(images/background/2.jpg) top"
            data-stellar-background-ratio=".5"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center text-light">
                    <h2>Benefits</h2>
                    <div className="spacer-20" />
                  </div>
                </div>
              </div>
              <div className="wow fadeInUp mb-5" data-wow-delay="0s">
                <h4 className="text-center mb-5 text-white">
                  For Funders and Investors
                </h4>
                <div className="row">
                  <div className="col-md-4">
                    <div className="f-box f-icon-left">
                      <i className="fa  fa-compress text-light" />
                      <div className="fb-text text-white">
                        <p>
                          Align the flow of resources to an ecosystem to reach
                          those generating the deepest impact
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="f-box f-icon-left">
                      <i className="fa icon_mail_alt text-light" />
                      <div className="fb-text text-white">
                        <p>
                          Invite funder and investor peers to make collective
                          efforts add up to something greater than the sum of
                          its parts.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="f-box f-icon-left">
                      <i className="fa fa-bar-chart-o text-light" />
                      <div className="fb-text text-white">
                        <p>
                          Track current and new investments into key social
                          change ecosystems to target areas with the greatest
                          need and potential for impact.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wow fadeInUp" data-wow-delay="0s">
                <h4 className="text-center mb-5 text-white">
                  For Organizations
                </h4>
                <div className="row">
                  <div className="col-md-4">
                    <div className="f-box f-icon-left">
                      <i className="fa fa-users text-light" />
                      <div className="fb-text text-white">
                        <p>
                          Build an ecosystem of projects to articulate shared
                          impact and collective resource needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="f-box f-icon-left">
                      <i className="fa fa-dollar text-light" />
                      <div className="fb-text text-white">
                        <p>
                          Collaboratively fundraise for shared impact while
                          maintaining the autonomy and privacy of each
                          organization's financial status.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="f-box f-icon-left">
                      <i className="fa fa-pie-chart text-light" />
                      <div className="fb-text text-white">
                        <p>
                          Track current and new investments into projects,
                          organizations and ecosystems
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="partners" />
          <div className="py-5">
            <div className="container">
              <div className="padding40 round" data-bgcolor="#ffffff">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="text-center">
                      <h2>Partners</h2>
                      <div className="spacer-20" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      id="owl-logo"
                      className="logo-carousel owl-carousel owl-theme"
                    >
                      <img src="logos/bcef.png" className="img-fluid" alt="" />
                      <img src="logos/fsl.png" className="img-fluid" alt="" />
                      <img
                        src="logos/devlabs.png"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="logos/spectrum.png"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="logos/impact.png"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="logos/movement.png"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="logos/realize.png"
                        className="img-fluid"
                        alt=""
                      />
                      <img src="logos/randp.png" className="img-fluid" alt="" />
                      <img
                        src="logos/legacyworks.png"
                        className="img-fluid"
                        alt=""
                      />
                      <img
                        src="logos/obsidian.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt60 pb60 bg-color text-light">
            <div className="container">
              <div
                className="row align-items-center"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  className=""
                  style={{
                    marginBottom: '25px',
                    marginRight: '20px',
                    marginLeft: '20px',
                  }}
                >
                  <h3 className="no-bottom" style={{ textAlign: 'center' }}>
                    Ready To Collaborate?
                  </h3>
                </div>
                <div className="" style={{ marginBottom: '25px' }}>
                  <a
                    href="https://blueprint.kinde.com/knock-knock"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-border light"
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* content close */}

        {/* footer begin */}
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 m-auto">
                <div className="widget text-center">
                  <Link to="wrapper" smooth offset={50} duration={500}>
                    <img
                      alt=""
                      className="logo"
                      width={'120px'}
                      src={logoWhite}
                    />
                  </Link>
                  <div className="spacer-20" />
                  <p>
                    Blueprint Collaborative is powered by Full Spectrum Capital
                    Partners. Communities across the globe are already solving
                    many of the cultural, political, economic and environmental
                    challenges of our time.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center mb-sm-30">
                <div className="mt10">&copy; Copyright 2020 - Blueprint</div>
              </div>
            </div>
          </div>
        </footer>
        {/* footer close */}

        <div id="preloader">
          <div className="spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
